// src/context/themeContext.tsx

import React, {
  createContext,
  useState,
  useEffect,
  FC,
  ReactNode,
} from "react";

interface Palette {
  primary: string;
  secondary: string;
  background: string;
  text: string;
  border: string;
  buttonBackground: string;
  buttonForeground: string;
  buttonHoverBackground: string;

  // Add more color properties as needed
}

interface ThemeContextType {
  isDarkMode: boolean;
  palette: Palette;
  toggleTheme: () => void;
}

const lightPalette: Palette = {
  primary: "#253B94", // Dodger Blue
  secondary: "#f0f0f0", // Light Gray
  background: "#ffffff", // White
  text: "#000000", // Black
  border: "#000000", // Black
  buttonBackground: "#253B94",
  buttonForeground: "#ffffff",
  buttonHoverBackground: "#1a2d73",
  // Define more light theme colors
};

const darkPalette: Palette = {
  primary: "#253B94",
  secondary: "#333333", // Dark Gray
  background: "#000000", // Black
  text: "#ffffff", // White
  border: "#ffffff", // White
  buttonBackground: "#253B94",
  buttonForeground: "#ffffff",
  buttonHoverBackground: "#1a2d73",
  // Define more dark theme colors
};

interface ThemeProviderProps {
  children: ReactNode;
}

export const ThemeContext = createContext<ThemeContextType | undefined>(
  undefined
);

export const ThemeProvider: FC<ThemeProviderProps> = ({ children }) => {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [palette, setPalette] = useState<Palette>(lightPalette);

  const toggleTheme = () => {
    setIsDarkMode((prevMode) => !prevMode);
  };

  useEffect(() => {
    setPalette(isDarkMode ? darkPalette : lightPalette);
  }, [isDarkMode]);

  return (
    <ThemeContext.Provider value={{ isDarkMode, palette, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};
