exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-donate-index-tsx": () => import("./../../../src/pages/donate/index.tsx" /* webpackChunkName: "component---src-pages-donate-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-join-index-tsx": () => import("./../../../src/pages/join/index.tsx" /* webpackChunkName: "component---src-pages-join-index-tsx" */),
  "component---src-pages-ko-index-tsx": () => import("./../../../src/pages/ko/index.tsx" /* webpackChunkName: "component---src-pages-ko-index-tsx" */),
  "component---src-pages-notifications-index-tsx": () => import("./../../../src/pages/notifications/index.tsx" /* webpackChunkName: "component---src-pages-notifications-index-tsx" */),
  "component---src-pages-notifications-notification-id-index-tsx": () => import("./../../../src/pages/notifications/[notificationId]/index.tsx" /* webpackChunkName: "component---src-pages-notifications-notification-id-index-tsx" */)
}

